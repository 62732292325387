import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../Body/Body.css";
import htmlParser from "html-react-parser";
// const CountDownTimer = lazy(() => import("../CountDownTimer/Count"));


export default function Body({ bannerData }) {
    const imgCDNURL = process.env.REACT_APP_NATARAJAI_CDN_URL;

    return (
        <div className="banner" >
            <Container>
                <Row>
                    <Col>
                        <div>
                            <div >
                                <p className="oneliner"><b>{htmlParser(bannerData.oneliner)}
                                </b></p>
                            </div>
                            <p className="Headerline">{htmlParser(bannerData.Headerline)}</p>
                            <div className="onelinermobile">
                                <img src={imgCDNURL+"Mobilegif.gif"} alt="Nataraj" className="Natarajanimation"></img>
                                <p className="mobileHeaderline">{htmlParser(bannerData.mobileHeaderline)}</p>
                                <p className="mobileHeaderline2">{htmlParser(bannerData.mobileHeaderline2)}</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>

    );
}