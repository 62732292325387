import React, { lazy, Suspense, useState, useEffect } from "react";
import "../src/App.css";
import CustomHeader from "./components/Header";
import Body from "./components/Body/Body";
import Form from "./components/SignUpForm/Contactform";
import axios from "axios";
import CacheBuster from 'react-cache-buster';
import appversion from '../package.json';
import jsonData from "./components/ai-branding.json"; // Import the JSON file
import Natarajdata from "./components/nataraj.json"; // Import the JSON file

// Lazy load your components
const CustomFooter = lazy(() => import("./components/Footer/Footer"));
const NatarajComponent = lazy(() => import("./components/Devata/devata"));
const DivineDance = lazy(() => import("./components/DivineDance/DivineDance"));
const Empowerment = lazy(() => import("./components/Empowerment/Empowerment"));
const AngelAi = lazy(() => import("./components/AngelAi/angelai"));
const DualityDance = lazy(() => import("./components/DualityDance/dualityDance"));

function App() {
  const [webinarData, setWebinarData] = useState();
  const [bannerData, setBannerData] = useState();
  const [DevataData, setDevataData] = useState();
  const [DivineDanceData, setDivineDanceData] = useState();
  const [EmpowermentData, setEmpowermentData] = useState();
  const [AngelAiData, setAngelAiData] = useState();
  const [timerData, setTimerData] = useState();
  const [formData, setFormData] = useState();
  const [showOtherComponents, setShowOtherComponents] = useState(false); // State for lazy loading components

  const appVersion = appversion.version;

  const [links, setLinks] = useState(null);
  
  useEffect(() => {
    const fetchLinks = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_ANGELAI_JSON);
        setLinks(response.data);
      } catch (error) {
        console.error("Error fetching links:", error);
        setLinks(jsonData); // Use local JSON data if fetching fails
      }
    };

    if (!links) {
      fetchLinks(); // Fetch JSON only if the links data is not available
    }
  }, [links]);

  useEffect(() => {
    if (!links) {
      setLinks(jsonData); // Set local JSON data as fallback initially
    }
  }, []);

  useEffect(() => {
    const fetchNatarajJSON = async () => {
      try {
        const url = process.env.REACT_APP_NATARAJAIJSON_CDN_URL; // Use the CDN URL from environment variables
        const response = await axios.get(url);
        const natarajJsonData = response.data;
        setWebinarData(natarajJsonData);
        setBannerData(natarajJsonData.banner);
        setDevataData(natarajJsonData.Devata);
        setDivineDanceData(natarajJsonData.DivineDance);
        setEmpowermentData(natarajJsonData.Empowerment);
        setAngelAiData(natarajJsonData.AngelAi);
      } catch (error) {
        console.log(error);
        // If there's an error fetching the JSON from the CDN, use the local JSON data instead
        setWebinarData(Natarajdata);
        setBannerData(Natarajdata.banner);
        setDevataData(Natarajdata.Devata);
        setDivineDanceData(Natarajdata.DivineDance);
        setEmpowermentData(Natarajdata.Empowerment);
        setAngelAiData(Natarajdata.AngelAi);
      }
    };

    fetchNatarajJSON();
  }, []);

  // Listen for scroll events and trigger lazy loading when user scrolls past 50%
  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.body.offsetHeight;
      const scrollPosition = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
      const maxScroll = documentHeight - windowHeight;
      const percentage = (scrollPosition / maxScroll) * 100;

      if (percentage > 50) {
        setShowOtherComponents(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the scroll listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array ensures the effect runs only once, like componentDidMount

  return (
    <CacheBuster
      currentVersion={appVersion}
      isEnabled={true} // If false, the library is disabled.
      isVerboseMode={false} // If true, the library writes verbose logs to console.
      metaFileDirectory={"process.env.PUBLIC_URL"}
    >
      <div className="bodyContainer">
        <CustomHeader links={links} fetchpriority="high" />
        {bannerData &&
          <>
            <Body links={links} bannerData={bannerData} timerData={timerData} fetchpriority="high" />
            
            <Suspense fallback={<div>Loading...</div>}>
              <NatarajComponent DevataData={DevataData} />
              <DivineDance DivineDanceData={DivineDanceData} /> {/* Use DivineDanceData prop */}
            </Suspense>
            {showOtherComponents && (
            <Suspense fallback={<div>Loading...</div>}>  
              <AngelAi AngelAiData={AngelAiData} /> {/* Use AngelAiData prop */}
              <Empowerment EmpowermentData={EmpowermentData} /> {/* Use EmpowermentData prop */}
              <DualityDance EmpowermentData={EmpowermentData} />
            </Suspense> 
            )}
           
            {showOtherComponents && (
              <Suspense fallback={<div>Loading...</div>}>
                {/* Show more components as the user scrolls */}
                <Form links={links} formData={formData} />
                <CustomFooter links={links} />
              </Suspense>
            )}
           
          </>
        }
      </div>
    </CacheBuster>
  );
}

export default App;
